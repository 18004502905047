.forget {
	margin-top: 50px;
	margin-bottom: 50px;
}

.forget h1 {
	text-align: center;
	font-size: 30px;
	font-family: 'Microsoft Yahei';
}

.input {
	/*height: 60px;*/
}

.input-valid {
	width: 395px;
	margin-right: 15px;
}

.btn-login {
	width: 100%;
	height: 3rem;
	font-size: 20px;
	font-weight: 500;
}
