.check-box-item{
    line-height: 32px;
}
.project-meddle {
    margin-top: 30px;
    margin-bottom: 30px;
}

.project-meddle h1 {
    font-size: 30px;
    margin-top: 60px;
}

.project-meddle p {
    margin-top: 30px;
    margin-bottom: 50px;
}

.project-bottom {
    background: #FAFAFA;
    padding-top: 70px;
    padding-bottom: 50px;
}

.project-bottom p {
    font-size: 30px;
    padding-top: 100px;
}

.project-bottom h1 {
    font-size: 20px;
    text-align: center;
}

.project-form-box{
    padding-top: 2rem;
    width: 26%;
    margin-left: 37%;
    padding-bottom: 1rem;
}
.project-form-box input{
      height: 2.5rem;
  }
.login-form {
    /*max-width: 300px;*/
}
.login-form-forgot {
    float: right;
}
 .login-form-button {
    width: 100%;
}

 .project-item-title{
     font-size: 13px;
     font-weight: bolder;
     padding-bottom: 1rem;

 }