.fontStyle{
    color: white;
    /*width: 20rem;*/
}

.banner-font-middle{
    letter-spacing:2px;
    font-size:1.4rem;
    margin-top: 1rem;
    font-weight: bolder;
    text-align: center;
}


