.login {
	margin-top: 50px;
	margin-bottom: 50px;
}

.login h1 {
	text-align: center;
	font-size: 30px;
	font-family: 'Microsoft Yahei';
}

.input {
	/*height: 60px;*/
}

.label-password a {
	float: right;
	font-size: 14px;
}

.btn-login {
	width: 100%;
	height:3rem;
	font-size: 20px;
	font-weight: 500;
}

.register {
	font-size: 18px;
	margin-left: 196px;
}