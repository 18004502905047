html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1.5;
	color: black !important;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

.banner-button{
	color: white;
	width: 210px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 600;
	font-size: 20px;
	background-color: #0e7bff;
	border: solid 1px #0e7bff;
	cursor: pointer;
	height: 64px;
	padding: 20px 43px 18px 40px;
	border-radius: 36px;
}

.banner-button:hover{
	background-color: #2f8dfd;
}

.banner-button-noColor{
	color: white;
	width: 210px;
	height: 64px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 600;
	font-size: 20px;
	border-radius: 32px;
	border: solid 1px white;
	cursor: pointer;
}

.banner-button-white{
	color: #0e7bff;
	width: 210px;
	height: 64px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 600;
	font-size: 20px;
	border-radius: 32px;
	background-color: white;
	border: solid 1px white;
	cursor: pointer;
}

.white-button{
	color: #20aee5;
	width: 168px;
	height: 44px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 600;
	font-size: 20px;
	border-radius: 27px;
	background-color: white;
	cursor: pointer;
}

.logo {
    width: 120px;
    height: 80px;
    display: flex;
    align-items: center;
    float: left;
    margin-left:2.8rem;

}

img {
    vertical-align: middle;
    margin-top: 0px;
}
.header-title{
    margin-left:12px;
    font-weight:bolder;
    font-size:24px;
    color: white;
    width: 200px;
}


.ant-layout-header {
    position: fixed;
    width: 100%;
    z-index: 999;
    top: 0;
    height: auto;
    line-height: 45px;
    padding-left: 10rem;
    padding-right: 10rem;
    background-color: rgba(0, 0, 0, 0.25);
}



.floatRight {
    float: right;
}

/*.btn {*/
/*	float: right;*/
/*	*/
/*}*/
div.footer-center {
  text-align: left;
}

div > a,
#footer {
  color: #ffffff;
  font-size: 12px;
}

#footer > div {
  padding: 2px 0px;
}

.bottom-bar {
  margin-top: 30px;
  width: 100%;
  /*margin-left: 10%;*/
  padding-top: 1rem;
  background-color:#373737;
  /*color: rgba(255, 255, 255, 0.65);*/
  padding-bottom: 16px;
}

.ant-layout-footer{
  padding: 0 !important;
}

@media screen and (max-width: 992px) {
  /*当屏幕尺寸小于600px时，应用下面的CSS样式*/
  div.footer-center {
    text-align: center;
  }
}

.footer-wrap{
  margin-bottom: 5rem;
}
.info {
  /*border-left: 1px solid;*/
  /*padding-left: 20px;*/
}

.ant-carousel .slick-slide {
    text-align: center;
    height:auto;
    /*line-height: 160px;*/
    background: white;
    overflow: hidden;
}

.ant-carousel .slick-slide h3 {
    color: #fff;
}

.carousel-button{
    width: 198px;
    height: 56px;
    line-height: 56px;
    border-radius: 28px;
    background-color: #0e7bff;
    font-family: AlibabaPuHuiTi;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    text-align: center;
    margin-top: 39px;
}
.style_box__28rbV{
    /*width: 95%;*/
    /*margin-left: 10%;*/
    /*width: 16rem;*/
    height: 380px;
    /*background-color: #f5f5f5;*/
    overflow: hidden;
    border-radius: 3px;
}

.style_box3__2OMFH{
    height: 420px;
    overflow: hidden;
    border-radius: 8px;
    /*width: 90%;*/
    /*margin-right: 20px;*/
    background-color: #f3f3f3;
    overflow: hidden;
}


.style_flexWrapper__11Ftq{
}


/*.box3:not(:first-child){*/
/*    margin-left: 200px!important;*/
/*}*/


.style_imageContainer__3zMWa{
    width: 100%;
    height: 50%;
}

.style_imageContainer3__2Lkte{
    width: 100%;
    height: 50%;
}

.style_imgStyle__1oe6K{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
img{
    /*width: 100%;*/
}

.style_title__Rdvvl{
    font-weight: bolder;
    font-size: 20px;
    /*color: black;*/
    height:2.8rem;
    text-align: center;
    text-align: center;
    padding-top: 16px;
    font-family: AlibabaPuHuiTi;

}

.style_detail__1_A-y{
    /*text-align: center;*/
    /*letter-spacing: 1px;*/
    width: 80%;
    font-size: 14px;
    overflow: hidden; /* 控制溢出内容隐藏 */
    /*padding-left: 10px;*/
    /*padding-right: 10px;*/
    text-align: center;
    margin: 8px 28px 48px 28px ;
}



/*IMAGE_TITLE_IMAGE样式*/
.style_title2__2EYwL{
    text-align: center;
    color: black;
    height:40%;
    padding-top: 1rem;
}

.style_box2__2N85n{
    border-radius: 3px;
    width: 90%;
    height: 24.65rem;
    background-color: #f5f5f5;
    overflow: hidden;
    margin: 0 auto;
}

.style_box2__2N85n:hover .style_imageContainer__3zMWa{
    height: 0;
    -webkit-transition: all 0.4s linear;
    transition: all 0.4s linear;
}

.style_imageStyle2__UDh88{
    width: auto;
    height: 20%;
}

.style_box2__2N85n:hover .style_imageStyle2__UDh88{
    width: 80%;
    height: auto;
    -webkit-transition: all 0.4s linear;
    transition: all 0.4s linear;
}

/*.box3{*/
/*    border-radius: 3px;*/
/*    width: 90%;*/
/*    height: 24.65rem;*/
/*    background-color: #f5f5f5;*/
/*    overflow: hidden;*/
/*    margin: 0 auto;*/
/*}*/

.style_tips__22LEg{
    opacity: 0;
    margin-top: 30%;
}


.style_box2__2N85n:hover .style_tips__22LEg{
    text-align: center;
    opacity: 1;
    margin-top: 10%;
    -webkit-transition: all 0.8s linear;
    transition: all 0.8s linear;
}

.style_tagContent__3ucO6{
    margin-top: 0.5rem;
    width: 100%;
    display: flex;
    justify-content: center;
}
.style_tag__1U2qd{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 0.01rem 0.5rem;
    font-size: 12px;
    border-radius: 10px;
    background-color: #e5e4e4;
    margin-left: 0.5rem;
}

.style_childBox__rCXev{
    width:62%;
    margin-left: 19%;
    height: auto;
    /*background-color: red;*/
}

.style_cardLink__1_vCG{
    color:#0e7bff;
    text-align: center;
    margin-top: 10rem;
}

.style_selectedMenu__3bUU2{
    margin-right:16px;
    padding: 11px 82px;
    border-radius:3px;
    cursor:pointer;
    background-color:#3879F7;
    color: white;
    font-size: 16px;
}

.style_unSelectMenu__3vJK_{
    margin-right:16px;
    padding: 11px 82px;
    border-radius:3px;
    cursor:pointer;
    background-color: #eeecec;
    font-size: 16px;
}

.style_tail__1LCfH{
    height: 20px;
}
.tools-show-animate{
    -webkit-animation-name: fadeInUp;
            animation-name: fadeInUp;
    -webkit-animation-duration: 1.5s;
            animation-duration: 1.5s;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    -webkit-animation-direction: normal;
            animation-direction: normal;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

@-webkit-keyframes fadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translateY(20rem);
                transform: translateY(20rem);
    }
    to {

        opacity: 1;
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translateY(20rem);
                transform: translateY(20rem);
    }
    to {

        opacity: 1;
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
}


.img-banner {
	width: 100%;
	height: auto;
}

.index-font-style{
	zIndex:100;
	color:#7DFFFE;
	/*font-weight: bolder;*/
	font-size: 1.3rem;

}

.banner-font{
	font-family: fontStyle;
	font-size: 66px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1;
	letter-spacing: normal;
	text-align: center;
	color: #fff;

}

.index-position-center{
	position:absolute;
	left:50%;
	top:46%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
}

@-webkit-keyframes image-animation{
	0%{
		-webkit-transform: scale(1.4);
		        transform: scale(1.4);
    }
	100%{
		-webkit-transform: scale(1);
		        transform: scale(1);
	}


}

@keyframes image-animation{
	0%{
		-webkit-transform: scale(1.4);
		        transform: scale(1.4);
    }
	100%{
		-webkit-transform: scale(1);
		        transform: scale(1);
	}


}

.banner {
	overflow: hidden;
	margin-top: 20px;
}

.hot {
	background: #FAFAFA;
	height: 500px;
	padding-top: 50px;
}

.hot-title {
	text-align: center;
	font-family: AlibabaPuHuiTi;
	font-size: 40px;
	font-weight: bold;
}

.scroll{
	width: 100%;
	/*text-align: center;*/
	margin-top: 17px;
	display: block;
	color: #ffffff;
	font-size: 14px;
	font-family: "HelveticaNeu";
	letter-spacing: 2px;
}

.mouse-style{
	position: absolute;
	width: 100%;
	text-align: center;
	bottom:30px;
	-webkit-animation-name: moveDown;
	        animation-name: moveDown;
	-webkit-animation-duration: 2s;
	        animation-duration: 2s;
	-webkit-animation-timing-function: ease-in-out;
	        animation-timing-function: ease-in-out;
	-webkit-animation-iteration-count: infinite;
	        animation-iteration-count: infinite; /* 无限循环 */
}

@-webkit-keyframes moveDown {
	0% {
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}
	50% {
		-webkit-transform: translateY(10px);
		        transform: translateY(10px); /* 向下移动 100px */
	}
	100%{
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}
}

@keyframes moveDown {
	0% {
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}
	50% {
		-webkit-transform: translateY(10px);
		        transform: translateY(10px); /* 向下移动 100px */
	}
	100%{
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}
}

.hot-more {
	display: block;
	float: right;
	margin-top: -35px;
	margin-right: 15px;
	color: #ABABAB;
}

.solution {
	margin-bottom: 30px;
}

.iconSolution4 {
	width: 17px;
	height: 19px;
}

.ant-card-body {
	padding: 0%;
}
.demo {
	background: #FFF;
	margin-top: 50px;
	margin-bottom: 80px;
}

.img-demo {
	width: 100%;
	height: 100%;
}

.demo1 {
	background: #B8D8EB;
	height: 100%;
	padding-top: 100px;
	padding-left: 15px;
}

.demo1 span {
	color: #1890ff;
	font-size: 14px;
}

.demo1 span img {
	margin-left: 5px;
	margin-bottom: 5px;
}

.demo1 h1 {
	font-size: 18px;
	font-weight: bold;
}

.demo2 {
	background: #EED795;

}

.demo3 {
	background: #BBE1E7;
	height: 100%;
	padding-top: 100px;
	text-align: right;
	padding-right: 15px;
}

.demo3 span {
	font-size: 14px;
}

.demo3 span img {
	margin-right: 5px;
	margin-bottom: 5px;
}

.demo3 h1 {
	font-size: 18px;
	font-weight: bold;
}

.demo4 {
	background: #D6DFE8;
}

.publish {
	background: #132743;
}

.pubContent {
	border-bottom: 1px solid #555F6D;
	padding-bottom: 50px;
	padding-top: 50px;
	padding-left: 180px;
}

.publish span {
	margin-right: 50px;
	color: white;
	font-size: 30px;
}

.pbtn {
	width: 300px;
	height: 72px;
	padding-left: 20px;
	background-color: #22ADE5;
}

.pbtn img {
	cursor: pointer;
	margin-left: -30px;
	margin-bottom: 11px;
}

.solution-img:hover{
	height: 200px;

}

.ant-card-cover{
	overflow: hidden;
}
.img-demo:hover, .solution-img:hover {
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
	-webkit-transition: all .7s;
	transition: all .7s;
	position: relative;
	z-index: 100;
}

.img-demo {
	cursor: pointer;
	width: 100%;
	height: 100%;
}



button {
	cursor: pointer;
	color: #090909;
	padding: 0.5em 1.7em;
	font-size: 14px;
	border-radius: 0.5em;
	background: #e8e8e8;
	border: 1px solid #e8e8e8;
	-webkit-transition: all .3s;
	transition: all .3s;
	box-shadow: 6px 6px 12px #c5c5c5,
	-6px -6px 12px #ffffff;
}

button:hover {
	border: 1px solid white;
}

button:active {
	box-shadow: 4px 4px 12px #c5c5c5,
	-4px -4px 12px #ffffff;
}

.index-ico-container{
	display: flex;
}
.index-ico-box{
	display: flex;
	flex-direction: column;
	width:6rem;
	height: 5rem;
	align-items: flex-start;
}

.index-ico{
	/*margin-left: 0.4rem;*/
	height: 1rem;
	margin-top: 1.6rem;
}

.index-ico-font{
	margin-top: 0.8rem;
	font-weight: 600;
	font-size: 12px;
}

.index-ico-desc{
	text-align: start;
	margin-top: 0.6rem;
	width: 327px;
	height: 400px;
	/*margin: 36px 0 39px 526px;*/
	font-family: AlibabaPuHuiTi;
	font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 2.29;
	letter-spacing: normal;
	color: #000;
}

.cardLinkStyle{
	height: 18px;
	margin-top: -2px;
}

.ERP-title{
	width: 100%;
	height: 28px;
	margin: 0 0 19px;
	font-family: AlibabaPuHuiTi;
	font-size: 44px;
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: center;
	color: #000;
}

.ERP-desc{
	width: 1200px;
	height: 56px;
	font-family: AlibabaPuHuiTi;
	font-size: 24px;
	text-align: center;
	color: #4b4b4b;
}
.scroll-view-container{
    display: flex;
    /*overflow: hidden;*/
    position: relative;
    /*box-shadow: 0 0 15px -3px #dadada;*/
}
/*.scroll-view-container::before{*/
/*    z-index: 9999;*/
/*    position: absolute;*/
/*    content: '';*/
/*    width: 130px;*/
/*    height: 100%;*/
/*    background: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));*/
/*}*/

/*.scroll-view-container::after{*/
/*    right: 0;*/
/*    z-index: 9999;*/
/*    position: absolute;*/
/*    content: '';*/
/*    width: 130px;*/
/*    height: 100%;*/
/*    background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));*/
/*}*/
.login {
	margin-top: 50px;
	margin-bottom: 50px;
}

.login h1 {
	text-align: center;
	font-size: 30px;
	font-family: 'Microsoft Yahei';
}

.input {
	/*height: 60px;*/
}

.label-password a {
	float: right;
	font-size: 14px;
}

.btn-login {
	width: 100%;
	height:3rem;
	font-size: 20px;
	font-weight: 500;
}

.register {
	font-size: 18px;
	margin-left: 196px;
}
.regist {
	margin-top: 50px;
	margin-bottom: 50px;
}

.regist input{
	height: 3rem;
	/*输入框提示文字大小*/
	font-size: 14px;
}

.regist h1 {
	text-align: center;
	font-size: 30px;
	font-family: 'Microsoft Yahei';
}

.input {
	height: 3rem;
	font-size: 18px;
}

.btn-regist {
	margin-top: 1rem;
	width: 100%;
	height: 3rem;
	font-size: 20px;
	font-weight: 500;
}

.logi {
	font-size: 18px;
	margin-left: 165px;
}
.register-login{
	font-weight: bolder;
	color: #20aee5;
}

.register-login:hover{
	color: #1d96c2;
}

.get-verification-code{
	position:absolute;
	right:0.5rem;
	top:50%;
	-webkit-transform:translateY(-50%);
	        transform:translateY(-50%);
	padding: 0.4rem 1rem;
	background-color: #e7eef7;
	color: #20aee5;
	border-radius: 5px;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
	cursor: pointer;
	font-size: 12px;
}

.get-verification-code:hover{
	background-color: #e2e6ec;
}
.get-verification-code:active{
	background-color:#cfd4d9;
}

.btn-disable{
	background-color: #dbd9d9;
	color: #000000;
	opacity: 0.5;
	pointer-events: none;
}

.ant-form-vertical .ant-form-explain{
	margin-top: 10px;
}
.account {
	margin-top: 150px;
	margin-bottom: 60px;
	color: black;
	font-family: 'Microsoft Yahei';
}

.account h1 {
	text-align: center;
	font-size: 30px;
	font-family: 'Microsoft Yahei';
}

.myAccount-form{
	position: relative;
}
.myAccount-avatar{
	position: absolute;
	top:0;
	left: -10rem;
	cursor: pointer;
	/*overflow: hidden;*/

}

.myAccount-avatar-edit{
	opacity: 0;
}

.myAccount-avatar:hover .myAccount-avatar-edit{
	width: 100%;
	height: 100%;
	border-radius: 50%;
	-webkit-clip-path: polygon(0 100%, 100% 100%, 100% 50%, 0 50%);
	        clip-path: polygon(0 100%, 100% 100%, 100% 50%, 0 50%);
	background-color: #494848;
	position: absolute;
	top:0%;
	opacity: 0.5;
	-webkit-transition: background-color 600ms linear;
	transition: background-color 600ms linear;

	/*设置文字的样式*/
	display: flex;
	align-items: flex-end;
	justify-content: center;
	padding-bottom: 1rem;
	color: white;
}


.input {
	height: 40px;
}


.btn-login {
	width: 100%;
	height: 3rem;
	font-size: 20px;
	font-weight: 500;
}

.btn-avator {
	margin-left: 15px;
}
.forget {
	margin-top: 50px;
	margin-bottom: 50px;
}

.forget h1 {
	text-align: center;
	font-size: 30px;
	font-family: 'Microsoft Yahei';
}

.input {
	font-size: 14px;
	/*height: 60px;*/
}

.input-valid {
	width: 395px;
	margin-right: 15px;
}

.btn-login {
	width: 100%;
	height:	3rem;
	font-size: 20px;
	font-weight: 500;
}

.forget {
	margin-top: 50px;
	margin-bottom: 50px;
}

.forget h1 {
	text-align: center;
	font-size: 30px;
	font-family: 'Microsoft Yahei';
}

.input {
	/*height: 60px;*/
}

.input-valid {
	width: 395px;
	margin-right: 15px;
}

.btn-login {
	width: 100%;
	height: 3rem;
	font-size: 20px;
	font-weight: 500;
}

.banner {
	margin: .5px 0;
}

.about-banner-title{
	position:absolute;
	top:50%;
	left:50%;
	-webkit-transform:translate(-50%,-50%);
	        transform:translate(-50%,-50%);
	color: white;
	letter-spacing: 5px;
}

.about h1 {
	font-size: 18px;
	color: #5EA7EA;
	margin-top: 50px;
	margin-bottom: 20px;
}

.about {
	margin-bottom: 100px;
}


.banner>img
{
	width: 100%;
}
.banner-join {
	margin-top: 2.5px;
}


.creer {
	background-color: #FAFAFA;
	padding-bottom: 50px;
}

.creer h1 {
	color: #2488E2;
	font-size: 16px;
	margin-top: 30px;
}

.creer h2 {
	font-size: 16px;
	font-weight: bold;
	margin-bottom: 20px;
}

.creer span {
	color: #2488E2;
	font-size: 14px;
	margin-right: 10px;
}

.position {
	margin: 50px 0;
}

.position h1 {
	font-size: 25px;
	font-weight: bold;
}

.position h2 {
	margin-top: 30px;
	font-size: 20px;
	font-weight: bold;
}

.position p {
	margin-top: 20px;
	font-size: 16px;
	font-weight: bold;
}

.address {
	background-color: #FAFAFA;
	padding-bottom: 50px;
}

.address h1 {
	font-size: 27px;
	font-weight: bold;
	margin: 50px 0 30px 0;
}
.address p {
	font-size: 16px;
	font-weight: bold;
}

#allmap {
	height: 300px;
	margin-top: 30px;
}

.detail {
	margin: 50px 0;
}

.detail h1 {
	font-size: 30px;
	text-align: center;
	font-weight: bold;
	margin-bottom: 50px;
}

.btn-back {
	float: left;
	margin-bottom: 0px;
}

.p-inline {
	display: inline;
	margin-right: 100px;
}

p {
	font-size: 18px;
	font-weight: bold;
}
.banner-join>img 
{
	width: 100%
}
.style_bannerJoin__18V1s {
	margin-top: 0px;
}


.style_bannerMargin__1E-8- {
	margin-bottom: 50px;
}
.access {
    margin-top: 50px;
    margin-bottom: 50px;
}

.access h1 {
    font-size: 30px;
}

.access p {
    font-size: 18px;
    font-weight: 200;
    margin-top: 30px;
}

.access-platform {
    margin-top: 50px;
    background: #FAFAFA;
    padding-top: 50px;
    padding-bottom: 50px;
    margin-bottom: 50px;
}

.access-img {
    margin-top: 30px;
}
.check-box-item{
    line-height: 32px;
}
.project-meddle {
    margin-top: 30px;
    margin-bottom: 30px;
}

.project-meddle h1 {
    font-size: 30px;
    margin-top: 60px;
}

.project-meddle p {
    margin-top: 30px;
    margin-bottom: 50px;
}

.project-bottom {
    background: #FAFAFA;
    padding-top: 70px;
    padding-bottom: 50px;
}

.project-bottom p {
    font-size: 30px;
    padding-top: 100px;
}

.project-bottom h1 {
    font-size: 20px;
    text-align: center;
}

.project-form-box{
    padding-top: 2rem;
    width: 26%;
    margin-left: 37%;
    padding-bottom: 1rem;
}
.project-form-box input{
      height: 2.5rem;
  }
.login-form {
    /*max-width: 300px;*/
}
.login-form-forgot {
    float: right;
}
 .login-form-button {
    width: 100%;
}

 .project-item-title{
     font-size: 13px;
     font-weight: bolder;
     padding-bottom: 1rem;

 }
.style_fontTitle__1eiAN{
    width:70%;
    position: absolute;
    top:15vh;
    font-weight: bolder;
    font-size:1.8rem;
    color: white;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    text-align: center;
}

.style_container__3D8dO{
    position: relative;
    width: 100%;
    height: auto;
    /*padding: 50px 365px 11px 367px;*/
    background-color: #eeebe5;
    display: flex;
}

.style_topIconContainer__XtOhC{
    display: flex;
    align-items: center;
    justify-content:space-around;
    position: absolute;
    width: 75%;
    height: 100px;
    /*background-color: red;*/
    top:-50px;
    left:50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);

}

.style_content__3EkCs{
    margin: 0 auto;
    padding-top: 1rem;
    width: 64%;
    height: 20rem;
    /*background-color: lightblue;*/
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 3rem;
    padding-right: 3rem;
}

.style_contentItem__2l_IA{
    text-align: center;
    width: 12.5%;
    height: auto;
    /*background-color: white;*/

}

.style_detailItem__-HK8J{
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    background-color: white;
    margin-top: 0.5rem;
    border-radius: 3px;
    font-size: 12px;
}

.style_solutionBox__1O_hc{
    width: 45%;
    font-size: 3.5rem;
    font-weight: lighter;
    margin-bottom: 2rem;
    /*font-family:fantasy;*/
}

.style_solutionBox__1O_hc:not(:nth-child(2n+1)){
    margin-left: 8%;
}
.solution-meddle {
    padding-top: 50px;
    padding-bottom: 50px;
}

.solution-meddle img {
    clear: both;
    display: block;
    margin:auto;
    height: 40px;
    margin-bottom: 15px;
}

.solution-meddle span {
    margin-left: 30%;
}

.solution-bottom {
    background: #FAFAFA;
    padding-top: 50px;
    padding-bottom: 50px;
}

.solution-bottom h1 {
    text-align: center;
    font-size: 30px;
    font-weight: 600;
}

.solutioncase {
    margin-top: 30px;
}

.ant-card-meta-description {
    height: 60px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    overflow: hidden;
}

.banner-join>img
{
   width: 100%;
    height: auto;
}

.solution-desc-style{
    position: absolute;
    top:12.5rem;
    /*right: 26rem;*/
    left: 46rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.list-box:not(:last-child){
    padding: 1.2rem 1.5rem;
    width: 32%;
    height: 11rem;
    background-color: #f4f5f5;
    margin-right: 2%;
}
.list-box:last-child{
    padding: 1.2rem 1.5rem;
    width: 32%;
    height: 11rem;
    background-color: #f4f5f5;
}

.list-box-a:not(:last-child){
    border-radius: 0.5rem;
    width:18.4%;
    height: 320px;
    margin-right: 2%;
    background-color: white;
}
.list-box-a:last-child{
    border-radius:0.5rem;
    width: 18.4%;
    height: 320px;
    background-color: white;
}

.logo-style{
    width: 3.2rem;
    height: 3.2rem;
}
.list-font{
    font-weight:bolder;
    margin-top:1rem;
    font-size:12px;
    line-height:1.5rem;
    letter-spacing:0.05rem
}

.solution-icon-box{
    margin-top: 2rem;
    width: 5.4rem;
    height: 5rem;
    /*border: solid 1px;*/
    display: flex;
    flex-direction: column;
    align-items: center;
}

.solution-icon-box:not(:first-child){
    margin-left: 21px;
}

/*.case-name {*/
/*    margin:auto auto;*/
/*    font-size: 2em;*/
/*    font-weight: 700;*/
/*}*/

.case-name {
    margin:auto auto;
    font-size: 2em;
    font-weight: 700;
    margin-bottom: 1em;
}

.solutionCase-img{
    height: 311px;
    width: auto;
    margin: auto auto;
}

.icon-title {
    text-align: center;
    font-size: 1.15em;
    font-weight: 700;
}

.icon-desc {
    text-align: center;
    font-size: .7em;
    margin-top: .5em;
}

.solution-middle-font{
    color: white;
    font-size: 1.4rem;
    margin-top: 0.6rem;
}
.style_container__3u9Qm{
    width: 100%;
    height: 31.5rem;
    background-color: white;
}

.style_title__2Orut{
    font-size: 20px;
    font-weight: bolder;
    margin-top: 5rem;
    /*margin-left: 13rem;*/
}

.style_describe__LD4rB{
    width: 80%;
    /*margin-left: 13rem;*/
    margin-top: 1.5rem;
}
.style_fontTitle__3LlkE{
    position: absolute;
    /*top:30vh;*/
    font-weight: bolder;
    font-size:1.8rem;
    color: white;
    left: 30%;
    top: 28%;
    -webkit-transform: translate(-50%);
            transform: translate(-50%);
    text-align: center;
    font-family:'YouSheBiaoTiHei';
}
.style_fontTitle___5vz9{
    position: absolute;
    /*top:30vh;*/
    font-weight: bolder;
    font-size:1.8rem;
    color: white;
    left: 50%;
    top: 28%;
    -webkit-transform: translate(-50%);
            transform: translate(-50%);
    text-align: center;
    font-family:'YouSheBiaoTiHei';
}
.style_fontTitle__u6ICj{
    position: absolute;
    top:26vh;
    font-weight: bolder;
    font-size:1.8rem;
    color: white;
    left: 23%;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    text-align: center;
}

.style_solutionDetail__pbu1B{
    font-size: 14px;
    margin-top: 10px;
    line-height: 24px;
}

.bannerImg>img
{
    padding-top: 2.5px;
    width: 100%;
}

.caseMenu 
{
    height: 140px;
    background-color: #fafafa;
    text-align: center;
}
.caseMenu >div
{
    width: 80%;
    background-color: red;
    height: 100%;
    position: relative;
    margin: 0 auto;
}

@media screen and (max-width: 992px) {
    /*当屏幕尺寸小于600px时，应用下面的CSS样式*/
    div.footer-center {
      text-align: center;
    }
  }
  .nav-container
  {
    margin-top: 43px;
  }
  .nav-button>div>button
  {
      margin-bottom: 47px;
      width: 138px;
      height: 50px;
      background-color: #ffffff;
      border-radius: 25px;
  }
  .case-title
  {
    width: 128px;
    height: 45px;
    font-family: PingFangSC;
    font-size: 32px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
  }


/* src/App2/index.css */
.box-enter, .box-appear {
    opacity: 0;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%)
}
.box-enter-active, .box-appear-active {
    opacity: 1;
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transition: all 500ms;
    transition: all 500ms;
}
.box-exit {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
}

.box-exit-active {
    opacity: 0;
    -webkit-transform: scale(.6);
            transform: scale(.6);
    -webkit-transition: all 1000ms;
    transition: all 1000ms
}
.simpleTransition{
    margin-left: 100px;
}


.myProject-head{
    display: flex;
    width: 100%;
    padding-top: 40px;
    padding-bottom: 40px;
    text-align: center;
    font-size: 30px;
    font-weight: 400;
}
.myProject-head>span{
    margin-top: auto;
    margin-left: 40%;
}
.myProject-head>.send-btn{
    margin-top: auto;
    margin-left: 20%;
}


.container{
    display: flex;
    background-color: #fafafa;
    /*min-height: 400px;*/
    height: auto;
    padding-top: 40px;
    padding-bottom: 200px;
}


.project-list{
    border-radius: 2px;
    cursor: pointer;
    border-bottom: dashed;
    /*outline:dashed 1.5px grey;*/
}
.login-demand-header{
    margin-top: .2em;
}

.login-demand-header>img{
    width: 100%;
    height: 30em;
}

.demand-text{
    font-size: 26px;
}
.case-detail {
    padding-top: 3em;
    background: #fafafa;
    padding-bottom: 3em;
}

.case-detail-title{
    font-size: 1.7em;
    font-weight: 700;
}

.case-detail-item{
    font-size: 1.25em;
}

.case-detail-itemName {
    font-size: 1.5em;
    font-weight: 600;
    margin-top: .3em;
}

.case-detail-img01>img {
    width: 100%;
    padding-top: 1em;
    height: 35em;
}

.solution-section{
    /*margin-left: 8rem;*/
    background-color: white;
}

.solution-section-title{
    margin-top: 4.5rem;
    font-weight: bolder;
    font-size: 44px;
    color: black;
}

.solution-wrap{
    display: flex;
    justify-content: center;
}

.button-box{
    min-width:120px;
    height:35px;
    border-radius: 3px;
    font-size: 16px;
    background-color:#f3f3f3;
    display:flex;
    justify-content:center;
    align-items:center;
    cursor: pointer;
    color: black;
}



.style_fontStyle__1n1Fv{
    color: white;
    /*width: 20rem;*/
}

.style_banner-font-middle__1ENmF{
    letter-spacing:2px;
    font-size:1.4rem;
    margin-top: 1rem;
    font-weight: bolder;
    text-align: center;
}



.header{
	/*background: #FFFFFF;*/
}

.footer {
	background: #474747;
	color: #778393;
	text-align: center;
	padding-top: 40px;
     /*margin-bottom: 0px;*/
}


table, td, th {
	border: 1px solid #d8d8d8;
	height: 36px;
}

table {
	border-collapse: collapse;
	width: 100%;
}

th {
	height: 36px;
	background-color: #eaf9ff;
	text-align: center;
	line-height: 36px;
	font-weight: bolder;
	/*height: 70px;*/
}

@font-face {
	font-family: "fontStyle";  /* 可以随意命名 */
	src: url(/static/media/ysbth.1726685c.TTF) format("truetype");  /* 指定字体文件的路径和格式 */
}

@font-face {
	font-family: "numberStyle";  /* 可以随意命名 */
	src: url(/static/media/BebasNeue-Regular.b2b29306.ttf) format("truetype");  /* 指定字体文件的路径和格式 */
}

.tail-title{
	font-size: 16px;
}

.solution-detail{
	line-height: 34px;
	font-size: 14px;
}

.product-detail{
	font-size: 14px;
	line-height: 34px;
	font-weight: bolder;
}

.concat-detail{
	font-size: 14px;
	line-height: 34px;
}
/*==============================调整导航栏menu组件样式====================================*/

:root{
    /*--themeColor:#20aee5*/
    --themeColor:#0e7bff;
    --fontSize:16px;
}



    /*修改menu导航栏的下拉组件样式*/
.ant-menu-submenu-popup{
    background: rgba(0,0,0,0.5);
}

/*设置菜单背景色*/
.ant-menu {
    background-color: rgba(0, 0, 0, 0.01) !important;
    color: white !important;
}

/*设置菜单文字颜色*/
.ant-menu-horizontal > .ant-menu-item > a {
    color: white;
}

.ant-menu-horizontal > .ant-menu-item-selected > a {
    color: #1890ff;
}

/*去掉导航栏顶部的border-buttom*/
.ant-menu-horizontal{
    border-bottom:none;
}


.ant-menu-item:hover, .ant-menu-item-active, .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-submenu-active, .ant-menu-submenu-title:hover {
    /*background-color: rgba(255,255,255,0.5);*/
}

.ant-menu-item > a{
    color: white;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{
    background-color: rgba(255, 255, 255, 0.2);
}

/*导航栏选中后字体颜色*/
/*#20aee5*/
.ant-menu-horizontal > .ant-menu-item-selected > a{
    color: #0e7bff;
    color: var(--themeColor);
    /*选中后，文字底部下划线*/
    box-shadow: 0 -2px 0 0 #0e7bff inset;
    box-shadow: 0 -2px 0 0 var(--themeColor) inset;
    font-size: 16px;
    font-size: var(--fontSize)
}
/*鼠标移到导航栏选上时字体颜色*/
.ant-menu-horizontal > .ant-menu-item > a:hover{
    font-size: 16px;
    font-size: var(--fontSize);
    color: #0e7bff;
    color: var(--themeColor)
}

.ant-menu-item:hover, .ant-menu-item-active, .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-submenu-active, .ant-menu-submenu-title:hover{
    color: #0e7bff;
    color: var(--themeColor)
}

/*选中后下拉菜单的字体颜色*/
.ant-menu-horizontal > .ant-menu-item:hover, .ant-menu-horizontal > .ant-menu-submenu:hover, .ant-menu-horizontal > .ant-menu-item-active, .ant-menu-horizontal > .ant-menu-submenu-active, .ant-menu-horizontal > .ant-menu-item-open, .ant-menu-horizontal > .ant-menu-submenu-open, .ant-menu-horizontal > .ant-menu-item-selected, .ant-menu-horizontal > .ant-menu-submenu-selected {
    color: #0e7bff !important;
    color: var(--themeColor) !important;
}

.ant-menu-submenu-selected{
    /*中后下拉菜单的下划线*/
    box-shadow: 0 -2px 0 0 #0e7bff inset;
    box-shadow: 0 -2px 0 0 var(--themeColor) inset;
}

.ant-menu-item > a:hover{
    color: #0e7bff;
    color: var(--themeColor)
}

.ant-menu-horizontal > .ant-menu-item:hover, .ant-menu-horizontal > .ant-menu-submenu:hover, .ant-menu-horizontal > .ant-menu-item-active, .ant-menu-horizontal > .ant-menu-submenu-active, .ant-menu-horizontal > .ant-menu-item-open, .ant-menu-horizontal > .ant-menu-submenu-open, .ant-menu-horizontal > .ant-menu-item-selected, .ant-menu-horizontal > .ant-menu-submenu-selected{
    /*border-bottom-color: var(--themeColor);*/
    /*border-bottom: solid 2px;*/
    border: none;
    /*box-shadow: 0 -2px 0 0 var(--themeColor) inset;*/
}


/*设置menu选中后下划线的宽度*/
.ant-menu-item, .ant-menu-submenu-title {
    font-size: 16px;
    font-size: var(--fontSize);
    padding: 0px;
}
.ant-menu-horizontal > .ant-menu-item, .ant-menu-horizontal > .ant-menu-submenu:not(:last-child){
    border: none;
    margin-right: 3rem;
}


/*调整导航栏高度*/
/*.ant-layout-header{*/
/*    padding-top: 0.5rem;*/
/*    padding-bottom: 0.5rem;*/
/*}*/


/*===================调整Button组件样式==========================*/
.ant-btn-primary{
    background-color:#0e7bff;
    background-color:var(--themeColor);
    border-color: #0e7bff;
    border-color: var(--themeColor);
}
.ant-btn > i, .ant-btn > span{
    font-weight: bolder;
}


/*===================调整输入框之间的间距==========================*/
.ant-form-item{
    margin-bottom: 18px;
}

/*=====================调整头像的样式============================*/

.ant-menu-item .anticon, .ant-menu-submenu-title .anticon {
    min-width: auto;
    margin-right: auto;
    font-size: inherit;
}

/*=============调整走马灯背景===============*/
.ant-carousel .slick-slide{
    background-color: transparent;
}


/*走马灯按钮颜色*/
.ant-carousel .slick-dots li button{
    background-color: gray;
}

.ant-carousel .slick-dots li.slick-active button{
    background-color:#0e7bff;
    background-color:var(--themeColor);
    width: 16px;
}

/*调整走马灯底部按钮位置*/
.ant-carousel .slick-dots-bottom {
    bottom: 130px;
}

/*======================tab组件样式====================================*/
.ant-tabs-nav .ant-tabs-tab-active {
    color: #0e7bff;
    color: var(--themeColor);
    font-weight: 600;
}


/*居中输管理TMS解决方案上方的几个tab*/
.ant-tabs-nav-scroll{
    display: flex;
    justify-content: center;
}

.ant-tabs-nav{
    font-weight: 400;
    font-size: 24px;
}

.ant-tabs-ink-bar{
    background-color: #0e7bff;
    background-color: var(--themeColor);
}



/*======================设置select组件高度========================*/
.ant-select-selection--single{
    height: 2.5rem;

}

.ant-select-selection--single .ant-select-selection__rendered{
    line-height: 2.2rem;
}

/*=================去掉input输入框等组件label后面的：=======================*/
.ant-form-item-label > label::after{
    content: '';
}
