.account {
	margin-top: 150px;
	margin-bottom: 60px;
	color: black;
	font-family: 'Microsoft Yahei';
}

.account h1 {
	text-align: center;
	font-size: 30px;
	font-family: 'Microsoft Yahei';
}

.myAccount-form{
	position: relative;
}
.myAccount-avatar{
	position: absolute;
	top:0;
	left: -10rem;
	cursor: pointer;
	/*overflow: hidden;*/

}

.myAccount-avatar-edit{
	opacity: 0;
}

.myAccount-avatar:hover .myAccount-avatar-edit{
	width: 100%;
	height: 100%;
	border-radius: 50%;
	clip-path: polygon(0 100%, 100% 100%, 100% 50%, 0 50%);
	background-color: #494848;
	position: absolute;
	top:0%;
	opacity: 0.5;
	transition: background-color 600ms linear;

	/*设置文字的样式*/
	display: flex;
	align-items: flex-end;
	justify-content: center;
	padding-bottom: 1rem;
	color: white;
}


.input {
	height: 40px;
}


.btn-login {
	width: 100%;
	height: 3rem;
	font-size: 20px;
	font-weight: 500;
}

.btn-avator {
	margin-left: 15px;
}