.login-demand-header{
    margin-top: .2em;
}

.login-demand-header>img{
    width: 100%;
    height: 30em;
}

.demand-text{
    font-size: 26px;
}