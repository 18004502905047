.tools-show-animate{
    animation-name: fadeInUp;
    animation-duration: 1.5s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: forwards;
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20rem);
    }
    to {

        opacity: 1;
        transform: translateY(0);
    }
}
