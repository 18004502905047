
.img-banner {
	width: 100%;
	height: auto;
}

.index-font-style{
	zIndex:100;
	color:#7DFFFE;
	/*font-weight: bolder;*/
	font-size: 1.3rem;

}

.banner-font{
	font-family: fontStyle;
	font-size: 66px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1;
	letter-spacing: normal;
	text-align: center;
	color: #fff;

}

.index-position-center{
	position:absolute;
	left:50%;
	top:46%;
	transform: translate(-50%, -50%);
}

@keyframes image-animation{
	0%{
		transform: scale(1.4);
    }
	100%{
		transform: scale(1);
	}


}

.banner {
	overflow: hidden;
	margin-top: 20px;
}

.hot {
	background: #FAFAFA;
	height: 500px;
	padding-top: 50px;
}

.hot-title {
	text-align: center;
	font-family: AlibabaPuHuiTi;
	font-size: 40px;
	font-weight: bold;
}

.scroll{
	width: 100%;
	/*text-align: center;*/
	margin-top: 17px;
	display: block;
	color: #ffffff;
	font-size: 14px;
	font-family: "HelveticaNeu";
	letter-spacing: 2px;
}

.mouse-style{
	position: absolute;
	width: 100%;
	text-align: center;
	bottom:30px;
	animation-name: moveDown;
	animation-duration: 2s;
	animation-timing-function: ease-in-out;
	animation-iteration-count: infinite; /* 无限循环 */
}

@keyframes moveDown {
	0% {
		transform: translateY(0);
	}
	50% {
		transform: translateY(10px); /* 向下移动 100px */
	}
	100%{
		transform: translateY(0);
	}
}

.hot-more {
	display: block;
	float: right;
	margin-top: -35px;
	margin-right: 15px;
	color: #ABABAB;
}

.solution {
	margin-bottom: 30px;
}

.iconSolution4 {
	width: 17px;
	height: 19px;
}

.ant-card-body {
	padding: 0%;
}
.demo {
	background: #FFF;
	margin-top: 50px;
	margin-bottom: 80px;
}

.img-demo {
	width: 100%;
	height: 100%;
}

.demo1 {
	background: #B8D8EB;
	height: 100%;
	padding-top: 100px;
	padding-left: 15px;
}

.demo1 span {
	color: #1890ff;
	font-size: 14px;
}

.demo1 span img {
	margin-left: 5px;
	margin-bottom: 5px;
}

.demo1 h1 {
	font-size: 18px;
	font-weight: bold;
}

.demo2 {
	background: #EED795;

}

.demo3 {
	background: #BBE1E7;
	height: 100%;
	padding-top: 100px;
	text-align: right;
	padding-right: 15px;
}

.demo3 span {
	font-size: 14px;
}

.demo3 span img {
	margin-right: 5px;
	margin-bottom: 5px;
}

.demo3 h1 {
	font-size: 18px;
	font-weight: bold;
}

.demo4 {
	background: #D6DFE8;
}

.publish {
	background: #132743;
}

.pubContent {
	border-bottom: 1px solid #555F6D;
	padding-bottom: 50px;
	padding-top: 50px;
	padding-left: 180px;
}

.publish span {
	margin-right: 50px;
	color: white;
	font-size: 30px;
}

.pbtn {
	width: 300px;
	height: 72px;
	padding-left: 20px;
	background-color: #22ADE5;
}

.pbtn img {
	cursor: pointer;
	margin-left: -30px;
	margin-bottom: 11px;
}

.solution-img:hover{
	height: 200px;

}

.ant-card-cover{
	overflow: hidden;
}
.img-demo:hover, .solution-img:hover {
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
	-webkit-transition: all .7s;
	transition: all .7s;
	position: relative;
	z-index: 100;
}

.img-demo {
	cursor: pointer;
	width: 100%;
	height: 100%;
}



button {
	cursor: pointer;
	color: #090909;
	padding: 0.5em 1.7em;
	font-size: 14px;
	border-radius: 0.5em;
	background: #e8e8e8;
	border: 1px solid #e8e8e8;
	transition: all .3s;
	box-shadow: 6px 6px 12px #c5c5c5,
	-6px -6px 12px #ffffff;
}

button:hover {
	border: 1px solid white;
}

button:active {
	box-shadow: 4px 4px 12px #c5c5c5,
	-4px -4px 12px #ffffff;
}

.index-ico-container{
	display: flex;
}
.index-ico-box{
	display: flex;
	flex-direction: column;
	width:6rem;
	height: 5rem;
	align-items: flex-start;
}

.index-ico{
	/*margin-left: 0.4rem;*/
	height: 1rem;
	margin-top: 1.6rem;
}

.index-ico-font{
	margin-top: 0.8rem;
	font-weight: 600;
	font-size: 12px;
}

.index-ico-desc{
	text-align: start;
	margin-top: 0.6rem;
	width: 327px;
	height: 400px;
	/*margin: 36px 0 39px 526px;*/
	font-family: AlibabaPuHuiTi;
	font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 2.29;
	letter-spacing: normal;
	color: #000;
}

.cardLinkStyle{
	height: 18px;
	margin-top: -2px;
}

.ERP-title{
	width: 100%;
	height: 28px;
	margin: 0 0 19px;
	font-family: AlibabaPuHuiTi;
	font-size: 44px;
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: center;
	color: #000;
}

.ERP-desc{
	width: 1200px;
	height: 56px;
	font-family: AlibabaPuHuiTi;
	font-size: 24px;
	text-align: center;
	color: #4b4b4b;
}