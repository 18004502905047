.regist {
	margin-top: 50px;
	margin-bottom: 50px;
}

.regist input{
	height: 3rem;
	/*输入框提示文字大小*/
	font-size: 14px;
}

.regist h1 {
	text-align: center;
	font-size: 30px;
	font-family: 'Microsoft Yahei';
}

.input {
	height: 3rem;
	font-size: 18px;
}

.btn-regist {
	margin-top: 1rem;
	width: 100%;
	height: 3rem;
	font-size: 20px;
	font-weight: 500;
}

.logi {
	font-size: 18px;
	margin-left: 165px;
}
.register-login{
	font-weight: bolder;
	color: #20aee5;
}

.register-login:hover{
	color: #1d96c2;
}

.get-verification-code{
	position:absolute;
	right:0.5rem;
	top:50%;
	transform:translateY(-50%);
	padding: 0.4rem 1rem;
	background-color: #e7eef7;
	color: #20aee5;
	border-radius: 5px;
	user-select: none;
	cursor: pointer;
	font-size: 12px;
}

.get-verification-code:hover{
	background-color: #e2e6ec;
}
.get-verification-code:active{
	background-color:#cfd4d9;
}

.btn-disable{
	background-color: #dbd9d9;
	color: #000000;
	opacity: 0.5;
	pointer-events: none;
}

.ant-form-vertical .ant-form-explain{
	margin-top: 10px;
}