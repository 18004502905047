.access {
    margin-top: 50px;
    margin-bottom: 50px;
}

.access h1 {
    font-size: 30px;
}

.access p {
    font-size: 18px;
    font-weight: 200;
    margin-top: 30px;
}

.access-platform {
    margin-top: 50px;
    background: #FAFAFA;
    padding-top: 50px;
    padding-bottom: 50px;
    margin-bottom: 50px;
}

.access-img {
    margin-top: 30px;
}