.solution-meddle {
    padding-top: 50px;
    padding-bottom: 50px;
}

.solution-meddle img {
    clear: both;
    display: block;
    margin:auto;
    height: 40px;
    margin-bottom: 15px;
}

.solution-meddle span {
    margin-left: 30%;
}

.solution-bottom {
    background: #FAFAFA;
    padding-top: 50px;
    padding-bottom: 50px;
}

.solution-bottom h1 {
    text-align: center;
    font-size: 30px;
    font-weight: 600;
}

.solutioncase {
    margin-top: 30px;
}

.ant-card-meta-description {
    height: 60px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.banner-join>img
{
   width: 100%;
    height: auto;
}

.solution-desc-style{
    position: absolute;
    top:12.5rem;
    /*right: 26rem;*/
    left: 46rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.list-box:not(:last-child){
    padding: 1.2rem 1.5rem;
    width: 32%;
    height: 11rem;
    background-color: #f4f5f5;
    margin-right: 2%;
}
.list-box:last-child{
    padding: 1.2rem 1.5rem;
    width: 32%;
    height: 11rem;
    background-color: #f4f5f5;
}

.list-box-a:not(:last-child){
    border-radius: 0.5rem;
    width:18.4%;
    height: 320px;
    margin-right: 2%;
    background-color: white;
}
.list-box-a:last-child{
    border-radius:0.5rem;
    width: 18.4%;
    height: 320px;
    background-color: white;
}

.logo-style{
    width: 3.2rem;
    height: 3.2rem;
}
.list-font{
    font-weight:bolder;
    margin-top:1rem;
    font-size:12px;
    line-height:1.5rem;
    letter-spacing:0.05rem
}

.solution-icon-box{
    margin-top: 2rem;
    width: 5.4rem;
    height: 5rem;
    /*border: solid 1px;*/
    display: flex;
    flex-direction: column;
    align-items: center;
}

.solution-icon-box:not(:first-child){
    margin-left: 21px;
}

/*.case-name {*/
/*    margin:auto auto;*/
/*    font-size: 2em;*/
/*    font-weight: 700;*/
/*}*/

.case-name {
    margin:auto auto;
    font-size: 2em;
    font-weight: 700;
    margin-bottom: 1em;
}

.solutionCase-img{
    height: 311px;
    width: auto;
    margin: auto auto;
}

.icon-title {
    text-align: center;
    font-size: 1.15em;
    font-weight: 700;
}

.icon-desc {
    text-align: center;
    font-size: .7em;
    margin-top: .5em;
}

.solution-middle-font{
    color: white;
    font-size: 1.4rem;
    margin-top: 0.6rem;
}