/*==============================调整导航栏menu组件样式====================================*/

:root{
    /*--themeColor:#20aee5*/
    --themeColor:#0e7bff;
    --fontSize:16px;
}



    /*修改menu导航栏的下拉组件样式*/
.ant-menu-submenu-popup{
    background: rgba(0,0,0,0.5);
}

/*设置菜单背景色*/
.ant-menu {
    background-color: rgba(0, 0, 0, 0.01) !important;
    color: white !important;
}

/*设置菜单文字颜色*/
.ant-menu-horizontal > .ant-menu-item > a {
    color: white;
}

.ant-menu-horizontal > .ant-menu-item-selected > a {
    color: #1890ff;
}

/*去掉导航栏顶部的border-buttom*/
.ant-menu-horizontal{
    border-bottom:none;
}


.ant-menu-item:hover, .ant-menu-item-active, .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-submenu-active, .ant-menu-submenu-title:hover {
    /*background-color: rgba(255,255,255,0.5);*/
}

.ant-menu-item > a{
    color: white;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{
    background-color: rgba(255, 255, 255, 0.2);
}

/*导航栏选中后字体颜色*/
/*#20aee5*/
.ant-menu-horizontal > .ant-menu-item-selected > a{
    color: var(--themeColor);
    /*选中后，文字底部下划线*/
    box-shadow: 0 -2px 0 0 var(--themeColor) inset;
    font-size: var(--fontSize)
}
/*鼠标移到导航栏选上时字体颜色*/
.ant-menu-horizontal > .ant-menu-item > a:hover{
    font-size: var(--fontSize);
    color: var(--themeColor)
}

.ant-menu-item:hover, .ant-menu-item-active, .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-submenu-active, .ant-menu-submenu-title:hover{
    color: var(--themeColor)
}

/*选中后下拉菜单的字体颜色*/
.ant-menu-horizontal > .ant-menu-item:hover, .ant-menu-horizontal > .ant-menu-submenu:hover, .ant-menu-horizontal > .ant-menu-item-active, .ant-menu-horizontal > .ant-menu-submenu-active, .ant-menu-horizontal > .ant-menu-item-open, .ant-menu-horizontal > .ant-menu-submenu-open, .ant-menu-horizontal > .ant-menu-item-selected, .ant-menu-horizontal > .ant-menu-submenu-selected {
    color: var(--themeColor) !important;
}

.ant-menu-submenu-selected{
    /*中后下拉菜单的下划线*/
    box-shadow: 0 -2px 0 0 var(--themeColor) inset;
}

.ant-menu-item > a:hover{
    color: var(--themeColor)
}

.ant-menu-horizontal > .ant-menu-item:hover, .ant-menu-horizontal > .ant-menu-submenu:hover, .ant-menu-horizontal > .ant-menu-item-active, .ant-menu-horizontal > .ant-menu-submenu-active, .ant-menu-horizontal > .ant-menu-item-open, .ant-menu-horizontal > .ant-menu-submenu-open, .ant-menu-horizontal > .ant-menu-item-selected, .ant-menu-horizontal > .ant-menu-submenu-selected{
    /*border-bottom-color: var(--themeColor);*/
    /*border-bottom: solid 2px;*/
    border: none;
    /*box-shadow: 0 -2px 0 0 var(--themeColor) inset;*/
}


/*设置menu选中后下划线的宽度*/
.ant-menu-item, .ant-menu-submenu-title {
    font-size: var(--fontSize);
    padding: 0px;
}
.ant-menu-horizontal > .ant-menu-item, .ant-menu-horizontal > .ant-menu-submenu:not(:last-child){
    border: none;
    margin-right: 3rem;
}


/*调整导航栏高度*/
/*.ant-layout-header{*/
/*    padding-top: 0.5rem;*/
/*    padding-bottom: 0.5rem;*/
/*}*/


/*===================调整Button组件样式==========================*/
.ant-btn-primary{
    background-color:var(--themeColor);
    border-color: var(--themeColor);
}
.ant-btn > i, .ant-btn > span{
    font-weight: bolder;
}


/*===================调整输入框之间的间距==========================*/
.ant-form-item{
    margin-bottom: 18px;
}

/*=====================调整头像的样式============================*/

.ant-menu-item .anticon, .ant-menu-submenu-title .anticon {
    min-width: auto;
    margin-right: auto;
    font-size: inherit;
}

/*=============调整走马灯背景===============*/
.ant-carousel .slick-slide{
    background-color: transparent;
}


/*走马灯按钮颜色*/
.ant-carousel .slick-dots li button{
    background-color: gray;
}

.ant-carousel .slick-dots li.slick-active button{
    background-color:var(--themeColor);
    width: 16px;
}

/*调整走马灯底部按钮位置*/
.ant-carousel .slick-dots-bottom {
    bottom: 130px;
}

/*======================tab组件样式====================================*/
.ant-tabs-nav .ant-tabs-tab-active {
    color: var(--themeColor);
    font-weight: 600;
}


/*居中输管理TMS解决方案上方的几个tab*/
.ant-tabs-nav-scroll{
    display: flex;
    justify-content: center;
}

.ant-tabs-nav{
    font-weight: 400;
    font-size: 24px;
}

.ant-tabs-ink-bar{
    background-color: var(--themeColor);
}



/*======================设置select组件高度========================*/
.ant-select-selection--single{
    height: 2.5rem;

}

.ant-select-selection--single .ant-select-selection__rendered{
    line-height: 2.2rem;
}

/*=================去掉input输入框等组件label后面的：=======================*/
.ant-form-item-label > label::after{
    content: '';
}