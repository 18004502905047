.ant-carousel .slick-slide {
    text-align: center;
    height:auto;
    /*line-height: 160px;*/
    background: white;
    overflow: hidden;
}

.ant-carousel .slick-slide h3 {
    color: #fff;
}

.carousel-button{
    width: 198px;
    height: 56px;
    line-height: 56px;
    border-radius: 28px;
    background-color: #0e7bff;
    font-family: AlibabaPuHuiTi;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    text-align: center;
    margin-top: 39px;
}