.box{
    /*width: 95%;*/
    /*margin-left: 10%;*/
    /*width: 16rem;*/
    height: 380px;
    /*background-color: #f5f5f5;*/
    overflow: hidden;
    border-radius: 3px;
}

.box3{
    height: 420px;
    overflow: hidden;
    border-radius: 8px;
    /*width: 90%;*/
    /*margin-right: 20px;*/
    background-color: #f3f3f3;
    overflow: hidden;
}


.flexWrapper{
}


/*.box3:not(:first-child){*/
/*    margin-left: 200px!important;*/
/*}*/


.imageContainer{
    width: 100%;
    height: 50%;
}

.imageContainer3{
    width: 100%;
    height: 50%;
}

.imgStyle{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
img{
    /*width: 100%;*/
}

.title{
    font-weight: bolder;
    font-size: 20px;
    /*color: black;*/
    height:2.8rem;
    text-align: center;
    text-align: center;
    padding-top: 16px;
    font-family: AlibabaPuHuiTi;

}

.detail{
    /*text-align: center;*/
    /*letter-spacing: 1px;*/
    width: 80%;
    font-size: 14px;
    overflow: hidden; /* 控制溢出内容隐藏 */
    /*padding-left: 10px;*/
    /*padding-right: 10px;*/
    text-align: center;
    margin: 8px 28px 48px 28px ;
}



/*IMAGE_TITLE_IMAGE样式*/
.title2{
    text-align: center;
    color: black;
    height:40%;
    padding-top: 1rem;
}

.box2{
    border-radius: 3px;
    width: 90%;
    height: 24.65rem;
    background-color: #f5f5f5;
    overflow: hidden;
    margin: 0 auto;
}

.box2:hover .imageContainer{
    height: 0;
    transition: all 0.4s linear;
}

.imageStyle2{
    width: auto;
    height: 20%;
}

.box2:hover .imageStyle2{
    width: 80%;
    height: auto;
    transition: all 0.4s linear;
}

/*.box3{*/
/*    border-radius: 3px;*/
/*    width: 90%;*/
/*    height: 24.65rem;*/
/*    background-color: #f5f5f5;*/
/*    overflow: hidden;*/
/*    margin: 0 auto;*/
/*}*/

.tips{
    opacity: 0;
    margin-top: 30%;
}


.box2:hover .tips{
    text-align: center;
    opacity: 1;
    margin-top: 10%;
    transition: all 0.8s linear;
}

.tagContent{
    margin-top: 0.5rem;
    width: 100%;
    display: flex;
    justify-content: center;
}
.tag{
    width: fit-content;
    padding: 0.01rem 0.5rem;
    font-size: 12px;
    border-radius: 10px;
    background-color: #e5e4e4;
    margin-left: 0.5rem;
}

.childBox{
    width:62%;
    margin-left: 19%;
    height: auto;
    /*background-color: red;*/
}

.cardLink{
    color:#0e7bff;
    text-align: center;
    margin-top: 10rem;
}

.selectedMenu{
    margin-right:16px;
    padding: 11px 82px;
    border-radius:3px;
    cursor:pointer;
    background-color:#3879F7;
    color: white;
    font-size: 16px;
}

.unSelectMenu{
    margin-right:16px;
    padding: 11px 82px;
    border-radius:3px;
    cursor:pointer;
    background-color: #eeecec;
    font-size: 16px;
}

.tail{
    height: 20px;
}