.banner {
	margin: .5px 0;
}

.about-banner-title{
	position:absolute;
	top:50%;
	left:50%;
	transform:translate(-50%,-50%);
	color: white;
	letter-spacing: 5px;
}

.about h1 {
	font-size: 18px;
	color: #5EA7EA;
	margin-top: 50px;
	margin-bottom: 20px;
}

.about {
	margin-bottom: 100px;
}


.banner>img
{
	width: 100%;
}