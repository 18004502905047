.fontTitle{
    position: absolute;
    /*top:30vh;*/
    font-weight: bolder;
    font-size:1.8rem;
    color: white;
    left: 30%;
    top: 28%;
    transform: translate(-50%);
    text-align: center;
    font-family:'YouSheBiaoTiHei';
}