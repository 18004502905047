.logo {
    width: 120px;
    height: 80px;
    display: flex;
    align-items: center;
    float: left;
    margin-left:2.8rem;

}

img {
    vertical-align: middle;
    margin-top: 0px;
}
.header-title{
    margin-left:12px;
    font-weight:bolder;
    font-size:24px;
    color: white;
    width: 200px;
}


.ant-layout-header {
    position: fixed;
    width: 100%;
    z-index: 999;
    top: 0;
    height: auto;
    line-height: 45px;
    padding-left: 10rem;
    padding-right: 10rem;
    background-color: rgba(0, 0, 0, 0.25);
}



.floatRight {
    float: right;
}

/*.btn {*/
/*	float: right;*/
/*	*/
/*}*/