.case-detail {
    padding-top: 3em;
    background: #fafafa;
    padding-bottom: 3em;
}

.case-detail-title{
    font-size: 1.7em;
    font-weight: 700;
}

.case-detail-item{
    font-size: 1.25em;
}

.case-detail-itemName {
    font-size: 1.5em;
    font-weight: 600;
    margin-top: .3em;
}

.case-detail-img01>img {
    width: 100%;
    padding-top: 1em;
    height: 35em;
}

.solution-section{
    /*margin-left: 8rem;*/
    background-color: white;
}

.solution-section-title{
    margin-top: 4.5rem;
    font-weight: bolder;
    font-size: 44px;
    color: black;
}

.solution-wrap{
    display: flex;
    justify-content: center;
}

.button-box{
    min-width:120px;
    height:35px;
    border-radius: 3px;
    font-size: 16px;
    background-color:#f3f3f3;
    display:flex;
    justify-content:center;
    align-items:center;
    cursor: pointer;
    color: black;
}


