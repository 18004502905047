.myProject-head{
    display: flex;
    width: 100%;
    padding-top: 40px;
    padding-bottom: 40px;
    text-align: center;
    font-size: 30px;
    font-weight: 400;
}
.myProject-head>span{
    margin-top: auto;
    margin-left: 40%;
}
.myProject-head>.send-btn{
    margin-top: auto;
    margin-left: 20%;
}


.container{
    display: flex;
    background-color: #fafafa;
    /*min-height: 400px;*/
    height: auto;
    padding-top: 40px;
    padding-bottom: 200px;
}


.project-list{
    border-radius: 2px;
    cursor: pointer;
    border-bottom: dashed;
    /*outline:dashed 1.5px grey;*/
}