.banner-join {
	margin-top: 2.5px;
}


.creer {
	background-color: #FAFAFA;
	padding-bottom: 50px;
}

.creer h1 {
	color: #2488E2;
	font-size: 16px;
	margin-top: 30px;
}

.creer h2 {
	font-size: 16px;
	font-weight: bold;
	margin-bottom: 20px;
}

.creer span {
	color: #2488E2;
	font-size: 14px;
	margin-right: 10px;
}

.position {
	margin: 50px 0;
}

.position h1 {
	font-size: 25px;
	font-weight: bold;
}

.position h2 {
	margin-top: 30px;
	font-size: 20px;
	font-weight: bold;
}

.position p {
	margin-top: 20px;
	font-size: 16px;
	font-weight: bold;
}

.address {
	background-color: #FAFAFA;
	padding-bottom: 50px;
}

.address h1 {
	font-size: 27px;
	font-weight: bold;
	margin: 50px 0 30px 0;
}
.address p {
	font-size: 16px;
	font-weight: bold;
}

#allmap {
	height: 300px;
	margin-top: 30px;
}

.detail {
	margin: 50px 0;
}

.detail h1 {
	font-size: 30px;
	text-align: center;
	font-weight: bold;
	margin-bottom: 50px;
}

.btn-back {
	float: left;
	margin-bottom: 0px;
}

.p-inline {
	display: inline;
	margin-right: 100px;
}

p {
	font-size: 18px;
	font-weight: bold;
}
.banner-join>img 
{
	width: 100%
}