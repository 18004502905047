div.footer-center {
  text-align: left;
}

div > a,
#footer {
  color: #ffffff;
  font-size: 12px;
}

#footer > div {
  padding: 2px 0px;
}

.bottom-bar {
  margin-top: 30px;
  width: 100%;
  /*margin-left: 10%;*/
  padding-top: 1rem;
  background-color:#373737;
  /*color: rgba(255, 255, 255, 0.65);*/
  padding-bottom: 16px;
}

.ant-layout-footer{
  padding: 0 !important;
}

@media screen and (max-width: 992px) {
  /*当屏幕尺寸小于600px时，应用下面的CSS样式*/
  div.footer-center {
    text-align: center;
  }
}

.footer-wrap{
  margin-bottom: 5rem;
}
.info {
  /*border-left: 1px solid;*/
  /*padding-left: 20px;*/
}
