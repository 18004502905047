.scroll-view-container{
    display: flex;
    /*overflow: hidden;*/
    position: relative;
    /*box-shadow: 0 0 15px -3px #dadada;*/
}
/*.scroll-view-container::before{*/
/*    z-index: 9999;*/
/*    position: absolute;*/
/*    content: '';*/
/*    width: 130px;*/
/*    height: 100%;*/
/*    background: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));*/
/*}*/

/*.scroll-view-container::after{*/
/*    right: 0;*/
/*    z-index: 9999;*/
/*    position: absolute;*/
/*    content: '';*/
/*    width: 130px;*/
/*    height: 100%;*/
/*    background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));*/
/*}*/