.fontTitle{
    width:70%;
    position: absolute;
    top:15vh;
    font-weight: bolder;
    font-size:1.8rem;
    color: white;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
}

.container{
    position: relative;
    width: 100%;
    height: auto;
    /*padding: 50px 365px 11px 367px;*/
    background-color: #eeebe5;
    display: flex;
}

.topIconContainer{
    display: flex;
    align-items: center;
    justify-content:space-around;
    position: absolute;
    width: 75%;
    height: 100px;
    /*background-color: red;*/
    top:-50px;
    left:50%;
    transform: translateX(-50%);

}

.content{
    margin: 0 auto;
    padding-top: 1rem;
    width: 64%;
    height: 20rem;
    /*background-color: lightblue;*/
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 3rem;
    padding-right: 3rem;
}

.contentItem{
    text-align: center;
    width: 12.5%;
    height: auto;
    /*background-color: white;*/

}

.detailItem{
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    background-color: white;
    margin-top: 0.5rem;
    border-radius: 3px;
    font-size: 12px;
}

.solutionBox{
    width: 45%;
    font-size: 3.5rem;
    font-weight: lighter;
    margin-bottom: 2rem;
    /*font-family:fantasy;*/
}

.solutionBox:not(:nth-child(2n+1)){
    margin-left: 8%;
}