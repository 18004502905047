.fontTitle{
    position: absolute;
    top:26vh;
    font-weight: bolder;
    font-size:1.8rem;
    color: white;
    left: 23%;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
}

.solutionDetail{
    font-size: 14px;
    margin-top: 10px;
    line-height: 24px;
}