.container{
    width: 100%;
    height: 31.5rem;
    background-color: white;
}

.title{
    font-size: 20px;
    font-weight: bolder;
    margin-top: 5rem;
    /*margin-left: 13rem;*/
}

.describe{
    width: 80%;
    /*margin-left: 13rem;*/
    margin-top: 1.5rem;
}