
.bannerImg>img
{
    padding-top: 2.5px;
    width: 100%;
}

.caseMenu 
{
    height: 140px;
    background-color: #fafafa;
    text-align: center;
}
.caseMenu >div
{
    width: 80%;
    background-color: red;
    height: 100%;
    position: relative;
    margin: 0 auto;
}

@media screen and (max-width: 992px) {
    /*当屏幕尺寸小于600px时，应用下面的CSS样式*/
    div.footer-center {
      text-align: center;
    }
  }
  .nav-container
  {
    margin-top: 43px;
  }
  .nav-button>div>button
  {
      margin-bottom: 47px;
      width: 138px;
      height: 50px;
      background-color: #ffffff;
      border-radius: 25px;
  }
  .case-title
  {
    width: 128px;
    height: 45px;
    font-family: PingFangSC;
    font-size: 32px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
  }


/* src/App2/index.css */
.box-enter, .box-appear {
    opacity: 0;
    transform: translateX(-100%)
}
.box-enter-active, .box-appear-active {
    opacity: 1;
    transform: translateX(0%);
    transition: all 500ms;
}
.box-exit {
    opacity: 1;
    transform: scale(1);
}

.box-exit-active {
    opacity: 0;
    transform: scale(.6);
    transition: all 1000ms
}
.simpleTransition{
    margin-left: 100px;
}

